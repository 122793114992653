import { Stack, StackProps } from '@whiteaway/ui';

import ErrorPageIcon from './error-page-icon';

interface Props extends StackProps {}

/**
 * The ErrorPage component is used to display a custom error view in case something goes wrong inside the application.
 */
export const ErrorPage: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <Stack align="center" justify="center" spacing={6} {...rest}>
      <ErrorPageIcon />

      {children}
    </Stack>
  );
};

export default ErrorPage;
