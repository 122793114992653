import styled, { css } from 'styled-components';

type AbsoluteCenterAxis = 'horizontal' | 'vertical' | 'both';

const AXIS_STYLES_MAPPER = {
  horizontal: css`
    left: 50%;
    transform: translateX(-50%);
  `,
  vertical: css`
    top: 50%;
    transform: translateY(-50%);
  `,
  both: css`
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
};

export interface AbsoluteCenterProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * This determines what axis the centering should be applied to.
   * @default both
   */
  axis?: AbsoluteCenterAxis;
}

/**
 * The AbsoluteCenter component is used to center (both horizontally and vertically) an element relative to its parent dimensions
 */
export const AbsoluteCenter: React.FC<AbsoluteCenterProps> = (props) => {
  const { children, axis = 'both', ...rest } = props;

  return (
    <AbsoluteCenterRoot $axis={axis} {...rest}>
      {children}
    </AbsoluteCenterRoot>
  );
};

AbsoluteCenter.displayName = 'AbsoluteCenter';

export default AbsoluteCenter;

const AbsoluteCenterRoot = styled.div<{ $axis: AbsoluteCenterAxis }>`
  ${({ $axis }) => css`
    position: absolute;

    ${AXIS_STYLES_MAPPER[$axis]};
  `}
`;
