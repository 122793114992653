import Link from 'next/link';

import { Button, ButtonProps, forwardRef } from '@whiteaway/ui';

import { ROUTES } from '@/config';
import { useTranslations } from '@/contexts';

interface Props extends Omit<ButtonProps, 'variant'> {}

/**
 * The ErrorPageButton component is used to display the button of the ErrorPage.
 */
export const ErrorPageButton = forwardRef<Props, 'a'>((props, ref) => {
  const { asElement = Link, href = ROUTES.PROTECTED.HOME, children, ...rest } = props;

  const { translations } = useTranslations();
  const text = children || translations.components.errorPage.button;

  return (
    <Button ref={ref} size="lg" asElement={asElement} href={href} {...rest}>
      {text}
    </Button>
  );
});

ErrorPageButton.displayName = 'ErrorPageButton';

export default ErrorPageButton;
