import styled, { css } from 'styled-components';

import { Circle as CircleComponent } from '@/components';

const CIRCLE_SIZE_MOBILE = 250;

/**
 * The ErrorPageIcon component holds an svg of the fridge icon with a grey circle background. It's used on the error pages.
 */
export const ErrorPageIcon: React.FC = () => {
  return (
    <Circle size={300} background="surface.inactive">
      <svg width="75%" height="75%" viewBox="0 0 80 221" fill="none">
        <rect x="6" y="54" width="73" height="166" stroke="black" strokeWidth="2" />
        <path d="M5 125L80 125" stroke="black" strokeWidth="2" />
        <line x1="69" y1="138" x2="69" y2="158" stroke="black" strokeWidth="2" />
        <path
          d="M34 112.337C36.5185 109.819 43.0667 106.293 49.1111 112.337"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M34.5 63C33.5555 64.5741 30.5333 67.7222 26 67.7222"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M54.333 63C55.2775 64.5741 58.2997 67.7222 62.833 67.7222"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="49" cy="88" r="4" fill="black" />
        <circle cx="37" cy="88" r="4" fill="black" />
        <path
          d="M42 84.5C42 87.7532 40.9967 90.661 39.4214 92.7338C37.8466 94.8058 35.7437 96 33.5 96C31.2563 96 29.1534 94.8058 27.5786 92.7338C26.0033 90.661 25 87.7532 25 84.5C25 81.2468 26.0033 78.339 27.5786 76.2662C29.1534 74.1942 31.2563 73 33.5 73C35.7437 73 37.8466 74.1942 39.4214 76.2662C40.9967 78.339 42 81.2468 42 84.5Z"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M61 84.5C61 87.7532 59.9967 90.661 58.4214 92.7338C56.8466 94.8058 54.7437 96 52.5 96C50.2563 96 48.1534 94.8058 46.5786 92.7338C45.0033 90.661 44 87.7532 44 84.5C44 81.2468 45.0033 78.339 46.5786 76.2662C48.1534 74.1942 50.2563 73 52.5 73C54.7437 73 56.8466 74.1942 58.4214 76.2662C59.9967 78.339 61 81.2468 61 84.5Z"
          stroke="black"
          strokeWidth="2"
        />
        <rect x="32" y="37" width="7" height="17" rx="1" stroke="black" strokeWidth="2" />
        <rect x="39" y="37" width="7" height="17" stroke="black" strokeWidth="2" />
        <rect x="46" y="37" width="7" height="17" rx="1" stroke="black" strokeWidth="2" />
        <rect x="70" y="37" width="7" height="17" rx="1" stroke="black" strokeWidth="2" />
        <rect
          x="53"
          y="40.9497"
          width="7"
          height="17"
          rx="1"
          transform="rotate(-45 53 40.9497)"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M28.7646 35H6.08697L8.02048 47.89C8.46104 50.8272 10.9841 53 13.9541 53H20.8975C23.8674 53 26.3905 50.8272 26.8311 47.89L28.7646 35Z"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M17.9258 35L17.9258 19.75V19.75C17.9258 14.726 19.0108 9.76117 21.1067 5.19517L21.4258 4.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path d="M27.9258 40H7.42578" stroke="black" strokeWidth="2" />
        <path
          d="M21.1269 21.3367C19.3454 23.1182 18.7424 24.335 18.6378 25.149C18.5448 25.8731 18.8273 26.4624 19.4695 27.1046C20.1117 27.7468 20.701 28.0294 21.4251 27.9363C22.2391 27.8318 23.4559 27.2287 25.2374 25.4472C27.6033 23.0814 28.4194 19.7096 28.6895 17.8847C26.8646 18.1548 23.4928 18.9709 21.1269 21.3367Z"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M9.07751 14.6385C11.3397 16.9007 12.9278 17.7127 14.043 17.8559C15.0683 17.9876 15.8887 17.5736 16.7312 16.7311C17.5736 15.8886 17.9877 15.0682 17.856 14.0429C17.7127 12.9277 16.9008 11.3396 14.6386 9.07744C11.4278 5.86664 6.79928 4.88414 4.59677 4.59671C4.8842 6.79922 5.8667 11.4277 9.07751 14.6385Z"
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    </Circle>
  );
};

export default ErrorPageIcon;

const Circle = styled(CircleComponent)`
  ${({ theme }) => css`
    ${theme.breakpoints.only('mobile')} {
      max-width: ${CIRCLE_SIZE_MOBILE}px;
      max-height: ${CIRCLE_SIZE_MOBILE}px;
    }
  `}
`;
