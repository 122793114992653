import { Typography, TypographyProps, forwardRef } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

interface Props extends TypographyProps {}

/**
 * The ErrorPageText component is used to display the text of the ErrorPage
 */
export const ErrorPageText = forwardRef<Props, 'div'>((props, ref) => {
  const { textAlign = 'center', variant = 'bodyStrong', asElement = 'div', children, ...rest } = props;

  const { translations } = useTranslations();
  const text = children || translations.components.errorPage.defaultMessage;

  return (
    <Typography ref={ref} textAlign={textAlign} variant={variant} asElement={asElement} {...rest}>
      {text}
    </Typography>
  );
});

ErrorPageText.displayName = 'ErrorPageText';

export default ErrorPageText;
